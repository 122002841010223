@import './styles/variables';
@import './styles/global';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


body {
  background-color: 'white';
  font-family: 'Roboto', sans-serif;
}

main {
  max-width: 1200px;
  padding: 0px 1rem;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 70px;
}

#navbar {
  position: fixed;
  top: 0;
  margin-left: 100px;
  box-shadow: none !important;
  z-index: 999;
}

#navbarLogoContainer {
  position: fixed;
  display: block;
  width: 200px;
  height: 64px;
  left: 0px;
  background: white !important;
}

// Logo serving as the home button in the navbar.
#navbarLogo {
  position: fixed;
  height: 60px;
  top: 5px;
  left: 25px;
}

.MuiButton-text {
  color: white !important;
}

.MuiAppBar-colorPrimary {
  background-color: $darkPurple !important;
}

footer {
  position: fixed;
  bottom: 0;
  height: 40px;
  width: 100%;
  color: white;
  background-color: $darkPurple;
  display: table; 
}

footer > p {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}
