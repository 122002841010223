html, body {
  padding: 0;
  margin: 0;
}

// Images displayed as a banner spanning the entire viewport.
.bannerImage {
position: relative;
vertical-align: middle;
height: 100%;
margin: 0;
}
.bannerImage > img {
width: auto;
height: auto;
max-width: 100%;
max-height: 90%;
margin: 20px auto;
z-index: 10;
}
// Text displayed over a banner image.
.bannerImageText {
position: absolute;
display: table-cell;
top: 120px;
right: 200px;
z-index: 20;
font-size: xx-large;
}

// Images displayed within the main body.
.pageImage {
position: relative;
display: grid;
height: auto;
min-width: 300px;
width: 500px;
}

// Containers for content exposed in the main area.
.pageContent {
margin-left: auto;
margin-right: auto;
width: 90%;
}

// Reusable class for centering content.
.centered {
margin-left: auto;
margin-right: auto;
width: fit-content;
}

// Reusable class for placing content on either side of each other.
div.lrContainer {
display: grid;
width: 100%;
padding-top: 50px;
}

// Reusable class for placing content on the left.
.left {
display: block;
grid-column-start: 1;
grid-column-end: 2;
min-width: 300px;
width: 600px;
top: 0px;
text-align: right;
margin-right: 0px;
}
.left > img {
position: relative;
left: 90px;
}

// Reusable class for placing content on the right.
.right {
display: block;
grid-column-start: 3;
grid-column-end: 4;
min-width: 300px;
width: 600px;
top: 0px;
text-align: left;
margin-left: 0px;
}
